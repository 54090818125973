//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import WalletListTable from '@/modules/wallet/components/wallet-list-table.vue';

export default {
  name: 'app-wallet-list-page',
  
  components: {
    [WalletListTable.name]: WalletListTable,
  },

  data() {
    return {
      tab: 'client',
    };
  },
  computed: {
    ...mapGetters({
      loading: 'wallet/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      doFetch: 'wallet/list/doFetch',
    }),
  },
};
