// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n'
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.wallet.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.wallet.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  userId: new StringField('userId', label('userId'), {}),
  balance: new DecimalField('balance', label('balance'), {}),
  holdedBalance: new DecimalField('holdedBalance', label('holdedBalance'), {}),
  actualBalance: new DecimalField('actualBalance', label('actualBalance'), {}),
  owner: new JsonField('owner', label('owner'), {}),
  type: new EnumeratorField('type', label('type'), [
    { value: 'guide', label: enumeratorLabel('userType', 'guide'), i18n: `entities.wallet.enumerators.userType.guide`},
    { value: 'client', label: enumeratorLabel('userType', 'client'), i18n: `entities.wallet.enumerators.userType.client`},
    { value: 'company', label: enumeratorLabel('userType', 'company'), i18n: `entities.wallet.enumerators.userType.company`},
  ],{}),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class WalletModel extends GenericModel {
  static get fields() {
    return fields;
  }
}