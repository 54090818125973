import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class WalletPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.walletRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.walletImport,
    );
    this.walletAutocomplete = permissionChecker.match(
      Permissions.values.walletAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.walletCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.walletEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.walletDestroy,
    );
  }
}
